<template>
	<div class="outer">
		<div class="top">
			<div class="p">
				<p class="p1">资产总额</p>
				<p class="p2">{{amount||0}}</p>
			</div>
			<div class="p">
				<p class="p1">股票总数</p>
				<p class="p2">{{total||0}}</p>
			</div>
			<div class="p">
				<p class="p1">待释放股数</p>
				<p class="p2">{{remain||0}}</p>
			</div>
			<div class="p">
				<p class="p1">已释放股数</p>
				<p class="p2">{{release||0}}</p>
			</div>
		</div>
		<div class="list">
			<div class="title">股票明细</div>
			<table class="tab">
				<thead>
					<tr>
						<td>名称代码</td>
						<td>待释放市值/数量</td>
						<td>现价</td>
						<td>已释放股数</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in list" :key="index">
						<td>
							<p class="p1">{{ item.stock_name }}</p>
							<p class="p2">{{ item.stock_symbol }}</p>
						</td>
						<td>
							<p class="p1">{{ item.amount }}</p>
							<p class="p2">{{ item.remain }}</p>
						</td>
						<td>
							<p class="p1">{{ item.price }}</p>
							<p class="p2"></p>
						</td>
						<td>
							<p class="p1">{{item.release}}</p>
							<p class="p2"></p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="nolist" v-if="listLoading&&list.length==0">
			<div class="txt">暂无数据~</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'des_index',
		data() {
			return {
				token: "",
				amount:"",
				release:"",
				remain:"",
				total:"",
				list:[],
				listLoading: false,
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token;
				this.$axios({
					method: 'post',
					url: 'des_release/index', 
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.amount=res.data[0].amount;
						this.release=res.data[0].release;
						this.remain=res.data[0].remain;
						this.total=res.data[0].total;
					}
				});
				this.$axios({
					method: 'post',
					url: 'des_release/list', 
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.list=res.data;
					}
					this.listLoading=true;
				})
			}
		},
		methods: {
			
		},
	}
</script>
<style scoped>
	.outer{
		min-height: 100vh;
		overflow: hidden;
		background: white;
		color: #161616;
	}
	.top{
		margin: 10px;
		padding: 20px 10px;
		border-radius: 5px;
		text-align: center;
		background-image: linear-gradient(to right, #FDFCFA, #FEEFE8);
		box-shadow: 0px 0px 9px 0px rgba(207, 207, 207, 0.7);
		display: flex;
		justify-content: space-around;
	}
	.top .p1{
		color: #9E9A97;
		font-size: 13px;
	}
	.top .p2{
		margin-top: 3px;
		min-height: 20px;
	}
	.title{
		text-align: center;
		padding: 10px 0;
		background: #FA6B25;
		color: white;
	}
	.list{
		margin: 0 10px;
	}
	.list .tab{
		width: 100%;
		border-collapse: collapse;
	}
	.list .tab thead{
		color:#616060;
		font-size: 12px;
	}
	.list .tab thead td{
		padding: 10px 0 0;
	}
	.list .tab thead td:nth-child(4){
		text-align: right;
	}
	.list .tab tbody td{
		padding: 10px 0;
		border-bottom: 1px solid #f2f2f2;
	}
	.list .tab tbody td:nth-child(4){
		text-align: right;
	}
	.list .tab tbody td .p2{
		color: #9A9A9A;
		font-size: 12px;
		min-height: 16px;
	}
	.nolist{
		margin-top: 200px;
		text-align: center;
		color:#ADADAD;
	}
</style>